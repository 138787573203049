<template>
    <div class="fmcg">
        <div class="block-wrapper">
            <div class="first-screen">
                <div class="first-screen__text-container">
                        <h1 class="first-screen__title">ПРОДАВАЙ ВПЕЧАТЛЕНИЯ</h1>
                        <br><br>
                        <h3 class="first-screen__description">Получите сайт с высокой конверсией специально <br>сконструированный для продажи <br>авторских туров</h3>
                        <br><br>
                        <!-- <p class=""><b>Начните получать клиентов прямо сейчас!</b></p> -->
                        <BaseButton @click="goTo" class="button-fill first-screen__button">Начать<img src="../assets/images/button_arrow.svg" style="margin-left: 10px;"></BaseButton>
                </div>
                <div class="first-screen__picture">
                    <img src="../assets/images/Screen_1.jpg">
                    <!-- <div class=" d-sm-none">
                        <p class="small text-center text-sm-left"><br><br>Вы блогер? <a href="/bloggers">Скачайте приложение и начните зарабатывать прямо сейчас</a></p>
                        <div class="d-flex flex-column justify-center align-center align-md-start">
                            <p class="d-flex flex-column flex-sm-row align-center align-sm-start">
                                <a :href="appleLink"><img src="../assets/images/icon-app_store.png" class="pb-4 pb-md-0 pr-md-4"></a>
                                <a :href="googleLink"><img src="../assets/images/icon-google_play.png"></a>
                            </p>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="block-wrapper filters">
            <div class="d-flex justify-center align-center">
                <div class="d-flex flex-column flex-lg-row" style="max-width: 1280px;">
                    <div class="d-flex flex-column justify-center align-center">
                        <h1 class="text-center"><span style="color: #E61E4D">НАЧНИТЕ ПОЛУЧАТЬ КЛИЕНТОВ ПРЯМО СЕЙЧАС</span></h1>
                        <br>
                        <h3 class="text-center">Соберите сайт своего путешествия за 30 минут как конструктор <br>из необходимых элементов</h3>
                        <br>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-center align-center">
                <div class="d-flex flex-column flex-md-row flex-wrap" style="max-width: 1280px; margin-bottom: 20px;">
                    <div class="d-flex flex-column filters__column justify-center align-center">
                        <a class="fmcg__register-link" @click="goTo">
                            <span class="circle"><img class="circle__img" src="../assets/images/fmcg_business_1.png"></span> Мероприятия 
                        </a>
                        <a class="fmcg__register-link" @click="goTo">
                            <span class="circle"><img class="circle__img" src="../assets/images/fmcg_business_2.png"></span> Маршрут
                        </a>
                        <a class="fmcg__register-link" @click="goTo">
                            <span class="circle"><img class="circle__img" src="../assets/images/fmcg_business_3.png"></span> Города и страны 
                        </a>
                        <a class="fmcg__register-link" @click="goTo">
                            <span class="circle"><img class="circle__img" src="../assets/images/fmcg_business_4.png"></span> Впечатления
                        </a>
                        <a class="fmcg__register-link" @click="goTo">
                            <span class="circle"><img class="circle__img" src="../assets/images/fmcg_business_5.png"></span> Расписание по дням
                        </a>
                    </div>
                    <div class="d-flex flex-column filters__column justify-center align-center">
                        <a class="fmcg__register-link" @click="goTo">
                            <span class="circle"><img class="circle__img" src="../assets/images/fmcg_business_6.png"></span> Проживание
                        </a>
                        <a class="fmcg__register-link" @click="goTo">
                            <span class="circle"><img class="circle__img" src="../assets/images/fmcg_business_7.png"></span> Фотоотчеты с туров
                        </a>
                        <a class="fmcg__register-link" @click="goTo">
                            <span class="circle"><img class="circle__img" src="../assets/images/fmcg_business_8.png"></span> Организатор
                        </a>
                        <a class="fmcg__register-link" @click="goTo">
                            <span class="circle"><img class="circle__img" src="../assets/images/fmcg_business_9.png"></span> Сообщество 
                        </a>
                        <a class="fmcg__register-link" @click="goTo">
                            <span class="circle"><img class="circle__img" src="../assets/images/fmcg_business_10.png"></span> Транспорт
                        </a>
                    </div>
                    <div class="d-flex flex-column filters__column justify-center align-center">
                        <a class="fmcg__register-link" @click="goTo">
                            <span class="circle"><img class="circle__img" src="../assets/images/fmcg_business_11.png"></span> Активности
                        </a>
                        <a class="fmcg__register-link" @click="goTo">
                            <span class="circle"><img class="circle__img" src="../assets/images/fmcg_business_12.png"></span> Бронирование тура
                        </a>
                        <a class="fmcg__register-link" @click="goTo">
                            <span class="circle"><img class="circle__img" src="../assets/images/fmcg_business_13.png"></span> Цены и даты
                        </a>
                        <a class="fmcg__register-link" @click="goTo">
                            <span class="circle"><img class="circle__img" src="../assets/images/fmcg_business_14.png"></span> События
                        </a>
                        <a class="fmcg__register-link" @click="goTo">
                            <span class="circle"><img class="circle__img" src="../assets/images/fmcg_business_15.png"></span> Скидки и подарки
                        </a>
                    </div>
                </div>
            </div>
            <BaseButton @click="goTo" class="button-fill filters__button">Начать<img src="../assets/images/button_arrow.svg" style="margin-left: 10px;"></BaseButton>
        </div>
        <br>
        <div class="block-wrapper block-content bg-grey less-time">
            <div class="d-flex justify-center align-center">
                <div class="d-flex flex-column flex-lg-row" style="max-width: 1280px;">
                    <div class="d-flex flex-column">
                        <h1 class="text-center"><span style="color: #E61E4D">3 ПРОСТЫХ ШАГА</span></h1>
                        <!-- <br> -->
                    </div>
                </div>
            </div>
            <br><br>
            <div class="d-flex justify-center align-center">
                <div class="d-flex flex-column flex-md-row flex-wrap" style="max-width: 1280px;">
                    <div class="less-time__item">
                        <div class="text-center">
                            <h3>Создайте описание тура</h3>
                            <p>Опишите ваш тур, его преимущества, маршрут, проживание, транспорт, активности, ценности и эмоции, которые получат ваши клиенты. </p>
                            <img style="height: 400px" src="../assets/images/Create.png">
                            <!-- <p>В обмен на пост о вашем продукте для аудитории блогера вы сможете предложить депозит на ваши товары или услуги, а также установить денежное вознаграждение.</p> -->
                        </div>
                    </div>
                    <div class="less-time__item">
                        <div class="text-center">
                            <h3>Получите готовый сайт вашего тура</h3>
                            <p>Уже через несколько минут после начала вы получите готовый сайт с вашим путешествием.<br><br></p>
                            <img style="height: 400px" src="../assets/images/Ready.png">
                            <!-- <p>Отклики поступают от блогеров, готовых выполнить ваше задание и сделать публикации о вашем продукте для своей аудитории.</p> -->
                        </div>
                    </div>
                    <div class="less-time__item">
                        <div class="text-center">
                            <h3>Принимайте заявки на ваши путешествия</h3>
                            <p>Заявки на тур вы сможете увидеть в собственной административной панели, а также моментально получать в бот и на email.</p>
                            <img src="../assets/images/fmcg_9.png">
                            <div class="d-flex flex-column flex-sm-row justify-center align-center">
                                <!-- <p class="text-left">A. Cтоимость услуг; <br>B. Количество аудитории; <br>C. Рейтинг; <br>D. Примеры постов.</p> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <BaseButton @click="goTo" class="button-fill" >Создайте сайт прямо сейчас <img src="../assets/images/button_arrow.svg" style="margin-left: 10px;"></BaseButton>
        </div>
        <br>
        <br>
        <div class="">
            <div class="d-flex justify-center align-center">
                <div class="d-flex flex-column flex-lg-row" style="max-width: 1280px;">
                    <div class="d-flex flex-column">
                        <h1 class="text-center">НАШИ ПРЕИМУЩЕСТВА</h1>
                        <!-- <br> -->
                    </div>
                </div>
            </div>
        </div>

        <div class="block-wrapper">
            <div class="benefits__container">
                <div class="d-flex flex-column align-center align-md-end">
                    <div class="benefits" style="max-width: 560px;">
                        <!-- <h4 class="benefits__title">Богатый опыт</h4> -->
                        <div class="text-center text-md-left">
                            <h4 class="benefits__title">Фокус на конверсии</h4>
                            <!-- <p class="text-justify"><b>Сайт создан именно для Авторского тура, учитывая все его особенности.</b></p> -->
                            <p class="benefits__text">Сайт разработан командой и сообществом организаторов Авторских путешествий GeAdventures. Мы организовали более 300 маршрутов по 6-и континентам, совершили несколько кругосветных и посетили в общей сложности более 120-и стран. При проектировании сайта был учтён опыт продажи более 1500 мест в Авторских путешествиях и поведения пользователей, оставивших более 10,000 заявок на туры.</p>
                            <p class="benefits__text">Сайт создан таким образом, чтобы обеспечить максимальную конверсию посетителя в заявку на тур, а также удобство и простоту его использования.</p>
                            <!-- <div class="double  d-none d-md-flex flex-column flex-sm-row justify-left align-center">
                                <button-big-red class="d-flex m-0" :href="'https://brand.getcontent.ai/register' + query"><b>Попробуйте бесплатно</b></button-big-red>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-column col-12 col-md-6 justify-center align-center align-md-start">
                    <img width="100%" style="max-width: 590px;" src="../assets/images/restaurant_20.png">
                </div>
            </div>
        </div>
        <div class="block-wrapper">
            <div class="benefits__container">
                <div class="d-flex flex-column">
                    <div class="benefits" style="max-width: 560px;">
                        <h3 class="benefits__title">Скорость и функциональность</h3>
                        <div class="text-center text-md-left">
                            <p class="benefits__text">Добавление нового тура с помощью нашего конфигуратор займёт не более 30и минут и не требует каких-либо технических навыков. Скрывайте блоки там где у вас ещё нет контента по турам, добавляйте свой логотип, формируйте своё сообщество путешественников, кастомизируйте внешний вид и многое многое другое! Вы можете создать столько страниц туров сколько захотите, выбрав подходящий тариф</p>
                            <p class="benefits__text">Если у вас есть предложения по модулям, которые хотелось бы добавить - просто напишите нам, мы учтём все популярные пожелания!</p>
                            <p class="benefits__text"><b>Наслаждайтесь путешествиями, о технической стороне позаботимся мы.</b></p>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-column col-12 col-md-6 justify-center align-center align-md-start">
                    <img width="100%" style="max-width: 590px;" src="../assets/images/restaurant_20.png">
                </div>
            </div>
        </div>
        <div class="start">
            <!-- <a name="top"></a> -->
            <div class="start__plans__title">
                Выберите подходящий тариф
            </div>

            <!-- <div class="start__plans__description">
                <b>Для того, чтобы опубликовать вашу рекламную кампанию и начать получать отклики от блогеров, пожалуйста, выберите подходящий тариф</b>
            </div> -->

            <div class="start__plans">
                <div class="start__plans__item">
                    <div class="start__plans__item__icon"><img src="../assets/images/tariff-icon-1.png" /></div>
                    <div class="start__plans__item__title">Базовый</div>
                    <div class="start__plans__item__price">5000
                        <span class="start__plans__item__currency">Р</span>
                        <span class="start__plans__item__currency-month ">/мес.</span></div>
                    <div class="start__plans__item__text"><v-icon color="red" class="mr-2">mdi-check</v-icon>Посадочная страница для 1 тура </div>
                    <p>30 ДНЕЙ БЕСПЛАТНО</p>
                    <p>К оплате сегодня: 0 рублей</p>
                    <BaseButton @click="goTo" class="button-fill" style="margin-top: 20px; width: 100%">Выбрать</BaseButton>
                </div>
                <div class="start__plans__item">
                    <div class="start__plans__item__icon"><img src="../assets/images/tariff-icon-2.png" /></div>
                    <div class="start__plans__item__title">Стандарт</div>
                    <div class="start__plans__item__price">8000
                        <span class="start__plans__item__currency">Р</span>
                        <span class="start__plans__item__currency-month ">/мес.</span></div>
                    <div class="start__plans__item__text"><v-icon color="red" class="mr-2">mdi-check</v-icon>Посадочная страница для 2 туров</div>
                    <p>30 ДНЕЙ БЕСПЛАТНО</p>
                    <p>К оплате сегодня: 0 рублей</p>
                    <BaseButton @click="goTo" class="button-fill" style="margin-top: 20px; width: 100%">Выбрать</BaseButton>
                </div>
                <div class="start__plans__item">
                    <div class="start__plans__item__icon"><img src="../assets/images/tariff-icon-3.png" /></div>
                    <div class="start__plans__item__title">Оптимальный</div>
                    <div class="start__plans__item__price">11000
                        <span class="start__plans__item__currency">Р</span>
                        <span class="start__plans__item__currency-month ">/мес.</span></div>
                    <div class="start__plans__item__text"><v-icon color="red" class="mr-2">mdi-check</v-icon>Посадочные страницы для 3-4х туров</div>
                    <p>30 ДНЕЙ БЕСПЛАТНО</p>
                    <p>К оплате сегодня: 0 рублей</p>
                    <BaseButton @click="goTo" class="button-fill" style="margin-top: 20px; width: 100%">Выбрать</BaseButton>
                </div>
                <div class="start__plans__item">
                    <div class="start__plans__item__icon"><img src="../assets/images/tariff-icon-black-4.png" /></div>
                    <div class="start__plans__item__title">Продвинутый</div>
                    <div class="start__plans__item__price">15000
                        <span class="start__plans__item__currency">Р</span>
                        <span class="start__plans__item__currency-month ">/мес.</span></div>
                    <div class="start__plans__item__text"><v-icon color="red" class="mr-2">mdi-check</v-icon>Посадочные страницы для 5 и более туров</div>
                    <p>30 ДНЕЙ БЕСПЛАТНО</p>
                    <p>К оплате сегодня: 0 рублей</p>
                    <BaseButton @click="goTo" class="button-fill" style="margin-top: 20px; width: 100%">Выбрать</BaseButton>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import BaseButton from '../components/common/BaseButton.vue';

    import moment from '@/plugins/moment';
    moment.locale('ru');

    export default {
        name: 'LP',
        components: {
            BaseButton,
        },
        data: () => ({
            timerDate: '',
            timer: null
        }),
        computed: {
            ...mapState('auth', ['user']),
        },
        methods: {
            toggleDialogUserType(e) {
                this.$store.commit('setDialogUserType', !this.showDialogUserType);
            },
            async authorization() {
                this.$root.$emit('popupLoginShow');
            },
            async goTo() {
                if(this.user._id) {
                    await this.$router.push({ name: 'trip-create' });
                } else {
                    this.authorization()
                }
            },
        },
    };
</script>

<style lang="scss">
.fmcg p > a {
    color: #0E0E0E;
    text-decoration: underline;
}
.fmcg img {
    max-width: 100%;
}
.fmcg {
    max-width: 1280px;
    width: 1280px;
    @media all and (max-width: 768px) {
        width: 100%;
        padding: 20px;
    }
}
.v-application a.fmcg__register-link {
    display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 52px;
    color: #0E0E0E;
    text-align: left;
    width: 100%;
    padding: 9px;
    margin-bottom: 6px;
    border-radius: 10px;
}
.v-application a.fmcg__register-link:hover {
    color: #E61E4D;
    background: #F7F7F7 url('../assets/images/fmcg_register_arrow.svg') right 20px center no-repeat;
}
.fmcg__register-link .circle {
    display: flex;
    float: left;
    width: 52px;
    height: 52px;
    border-radius: 50%;
    background: #ECECEC;
    margin-right: 18px;
    // background-size: 70%;
    // background-position: center center;
    // background-repeat: no-repeat;
    justify-content: center;
    align-items: center;
    &__img {
        height: 40px;
        width: 40px;

    }
}
.first-screen {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media all and (max-width: 1470px) {
        margin: 20px;
    }
    @media all and (max-width: 768px) {
        margin: 0;
        flex-direction: column;
    }
    &__text-container {
        padding: 0 0 20px;
        max-width: 650px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    &__title {
        font-size: 36px;
        margin: 30px auto 0 0;
        @media all and (max-width: 768px) {
            font-size: 32px;
            // margin: 0 auto 0;
        }
    }
    &__description {
        font-size: 22px;
        text-align: left;
    }
    &__button {
        width: 250px;
        margin: 0 auto 0 0;
        @media all and (max-width: 768px) {
            width: 100%;
        }
    }
    &__picture {
        max-width: 600px;
    }
}

.filters{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &__column {
        @media (min-width: 1264px){
            // width:  33.33%;
            width: 420px;
        }
    }
    &__button {
        margin: 0 auto;
        width: 250px;
        @media (max-width: 768px){
            width:  calc(100% - 20px);
        }
    }
}

.benefits {
    font-family: 'Inter';
    font-style: normal;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    &__title {
        font-size: 30px;
        margin: 0 auto 10px;
        color: #E2231A;
        text-align: center;
    }
    &__container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        max-width: 1280px;
        margin: 0 auto;
        @media all and (max-width: 768px) {
            flex-direction: column;
        }
    }
    &__text {
        text-align: left;
    }
}

.block-wrapper {
    margin: 50px auto;
}

.less-time {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    &__item {
        max-width: 400px;
        margin-right: 20px;
        margin-bottom: 20px;
        &:last-of-type{
            margin-right: 0;
        }
        @media all and (max-width: 768px) {
            width: 100%;
            margin-right: 0;
            margin-bottom: 20px;
        }
    }
}

.start{
    margin: 30px auto;
    &__plans {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        @media all and (max-width: 768px) {
            flex-direction: column;
        }
        &__title {
            font-weight: 600;
            font-size: 36px;
            margin-bottom: 35px;
            text-align: center;
            // @media screen and (max-width: 768px) {
            //     font-size: 26px;
            // }
        }
        &__description {
            max-width: 800px;
            font-size: 20px;
            margin-bottom: 35px;
            text-align: center;
            margin: 0 auto 40px;
            // @media screen and (max-width: 768px) {
            //     font-size: 14px;
            // }
        }
        &__item {
            position: relative;
            overflow: hidden;
            padding: 50px 25px 25px 25px;
            display: flex;
            flex-direction: column;
            align-items: center;
            min-width: 260px;
            width: 300px;
            /*height: 782px;*/
            border: 1px solid #E4E3E7;
            box-sizing: border-box;
            box-shadow: 0 4px 20px rgba(186, 185, 196, 0.3);
            border-radius: 12px;
            margin: 10px 0;
            transition: border-color .2s;
            @media screen and (max-width: 768px) {
                width: 100%;
            }
            &__title {
                font-size: 24px;
                line-height: 120%;
                text-align: center;
                color: #0D0D12;
                margin-bottom: 24px;
            }
            &__price {
                font-weight: 600;
                font-size: 36px;
                color: #E2231A;
                text-align: center;
            }
            &__currency {
                display: inline-block;
                font-size: 26px;
                font-weight: bold;
                color: #E2231A;
                margin-right: 4px;
            }
            &__currency-month {
                display: inline-block;
                font-size: 24px;
                font-weight: normal;
                color: #E2231A;
            }
            &__text {
                font-size:16px;
                padding: 10px 0;
                text-align: center;
                align-self: flex-start;
            }
        }
    }
}

</style>